import type { Source, SummarySchedule } from "@prisma/client";
import { useCallback, useState } from "react";
import {
  Form,
  redirect,
  useLoaderData,
  useSubmit,
  type ActionFunctionArgs,
  type LoaderFunctionArgs,
  type MetaFunction,
} from "react-router";
import { db } from "~/.server/db";
import { requireAppToken } from "~/.server/token";
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "~/components/ui/alert-dialog";
import { Button } from "~/components/ui/button";
import { Label } from "~/components/ui/label";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "~/components/ui/select";
import { Switch } from "~/components/ui/switch";
import { cn, invariant } from "~/lib/utils";
import { decodeShortId, scheduleOptions } from "~/model/source";
import { getSourceMeta, SourceTypeSettings } from "~/sources/config";
import type { SettingsSourceShape } from "./layout";
import { SettingsBody, SettingsContent, SettingsHeader, SettingsRow, SettingTitle } from "./layout";

function getShortId(params: LoaderFunctionArgs["params"]) {
  invariant(params.shortId, "shortId is required");
  const { shortId } = decodeShortId(params.shortId);
  if (shortId == null) {
    throw new Response("Not Found", { status: 404 });
  }
  return shortId;
}

export async function loader({ request, params }: LoaderFunctionArgs) {
  const token = await requireAppToken(request);
  const shortId = getShortId(params);
  const source = await db.source.findUnique({ where: { shortId, userId: token.userId } });
  if (!source) {
    console.error(`Source not found: ${params.sourceSlug}`);
    throw new Response("Not Found", { status: 404 });
  }
  return { source };
}

export async function action(args: ActionFunctionArgs) {
  const { request, params } = args;
  const { userId } = await requireAppToken(request);
  const formData = await request.formData();
  const shortId = getShortId(params);
  const action = formData.get("action");
  if (action === "set-enabled") {
    const enabled = formData.get("enabled") === "true";
    await db.source.update({ where: { shortId, userId }, data: { enabled } });
  } else if (action === "set-include-in-podcast") {
    const includeInPodcast = formData.get("includeInPodcast") === "true";
    await db.source.update({ where: { shortId, userId }, data: { includeInPodcast } });
  } else if (action === "set-config") {
    const config = JSON.parse(formData.get("config") as string);
    if (!config) {
      throw new Error("Invalid config");
    }
    await db.source.update({ where: { shortId, userId }, data: { config } });
  } else if (action === "remove-source") {
    return await removeSource(args);
  } else if (action === "set-schedule") {
    const schedule = formData.get("schedule") as SummarySchedule;
    await db.source.update({ where: { shortId, userId }, data: { schedule } });
  }
}

function EnabledToggle({ source }: { source: Source }) {
  const submit = useSubmit();
  const [enabled, _setEnabled] = useState(source.enabled);
  function setEnabled(enabled: boolean) {
    _setEnabled(enabled);
    const formData = new FormData();
    formData.append("action", "set-enabled");
    formData.append("enabled", enabled.toString());
    submit(formData, { method: "post" });
  }

  return (
    <div className="flex flex-row items-center gap-2">
      <Label htmlFor="enabled" className="text-sm text-foreground font-sans normal-case">
        Enabled
      </Label>
      <Switch name="enabled" checked={enabled} onCheckedChange={setEnabled} />
    </div>
  );
}

function SourceConfig({ source }: { source: SettingsSourceShape }) {
  const [config, _setConfig] = useState(source.config);
  const submit = useSubmit();
  const setConfig = useCallback(
    (config: SettingsSourceShape["config"]) => {
      _setConfig(config);
      const formData = new FormData();
      formData.append("action", "set-config");
      formData.append("config", JSON.stringify(config));
      submit(formData, { method: "post" });
    },
    [submit],
  );

  return <SourceTypeSettings source={source} config={config} setConfig={setConfig} />;
}

// SCHEDULE

function ScheduleRow({ source }: { source: SettingsSourceShape }) {
  const submit = useSubmit();
  const [schedule, _setSchedule] = useState(source.schedule);
  function setSchedule(schedule: string) {
    _setSchedule(schedule as SummarySchedule);
    const formData = new FormData();
    formData.append("action", "set-schedule");
    formData.append("schedule", schedule);
    submit(formData, { method: "post" });
  }

  return (
    <SettingsRow>
      <Label htmlFor="schedule">New summaries are created</Label>
      <Select name="schedule" value={schedule} onValueChange={setSchedule}>
        <SelectTrigger className="w-32">
          <SelectValue placeholder="Select a schedule" />
        </SelectTrigger>
        <SelectContent>
          {scheduleOptions.map((s) => (
            <SelectItem key={s.value} value={s.value}>
              {s.label}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </SettingsRow>
  );
}

// INCLUDE IN PODCAST

function IncludeInPodcast({ source }: { source: SettingsSourceShape }) {
  const submit = useSubmit();
  const [includeInPodcast, _setIncludeInPodcast] = useState(source.includeInPodcast);
  function setIncludeInPodcast(includeInPodcast: boolean) {
    _setIncludeInPodcast(includeInPodcast);
    const formData = new FormData();
    formData.append("action", "set-include-in-podcast");
    formData.append("includeInPodcast", includeInPodcast.toString());
    submit(formData, { method: "post" });
  }

  return (
    <SettingsRow>
      <Label>Include in podcast</Label>
      <Switch checked={includeInPodcast} onCheckedChange={(checked) => setIncludeInPodcast(checked)} />
    </SettingsRow>
  );
}

// REMOVE SOURCE

async function removeSource(args: ActionFunctionArgs) {
  const token = await requireAppToken(args.request);
  const shortId = getShortId(args.params);
  await db.source.delete({ where: { shortId, userId: token.userId } });
  return redirect("/settings/sources");
}

function RemoveSource({ source }: { source: SettingsSourceShape }) {
  return (
    <SettingsRow>
      <Label>Remove source</Label>

      <AlertDialog>
        <AlertDialogTrigger asChild>
          <Button
            type="button"
            variant="outline"
            className="text-destructive dark:text-destructive-foreground hover:bg-destructive hover:text-destructive-foreground"
          >
            Remove...
          </Button>
        </AlertDialogTrigger>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Please confirm removal of {source.sourceName}</AlertDialogTitle>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <Form method="post">
              <Button type="submit" name="action" value="remove-source" variant="destructive">
                Remove Source
              </Button>
            </Form>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </SettingsRow>
  );
}

export default function SourcePage() {
  const { source } = useLoaderData<typeof loader>();
  const meta = getSourceMeta(source.sourceType);

  return (
    // need key to force re-mount when source changes
    <SettingsContent key={source.sourceId}>
      <SettingsHeader>
        <SettingTitle>
          <img src={meta.icon} className={cn("size-6", !source.enabled && "grayscale")} alt={meta.name} />
          <span className="grow">{source.sourceName}</span>
          <EnabledToggle source={source} />
        </SettingTitle>
      </SettingsHeader>
      <SettingsBody>
        <ScheduleRow source={source} />
        <SourceConfig source={source} />
        <IncludeInPodcast source={source} />
        <RemoveSource source={source} />
      </SettingsBody>
    </SettingsContent>
  );
}

// PAGE META

export const meta: MetaFunction = ({ data }) => {
  const { source } = data as ReturnType<typeof useLoaderData<typeof loader>>;
  return [{ title: `${source.sourceName} · Sumcast` }];
};

// SUMMARY LENGTH

export type SummaryLength = 1 | 2 | 3;

export function charsToLength(chars: number | undefined): SummaryLength {
  if (!chars) return 2;
  if (chars < 300) return 1;
  if (chars > 300) return 3;
  return 2;
}

export function lengthToChars(length: SummaryLength) {
  if (length === 1) return 100;
  if (length === 3) return 900;
  return 300;
}

export function lengthName(length: SummaryLength) {
  if (length === 1) return "Short";
  if (length === 3) return "Long";
  return "Medium";
}
